<template>
  <div class="w-full flex flex-col gap-1 p-1 rounded-sm mt-2">
    <h3 class="fugaz uppercase mb-2">New User info</h3>
    <div class="flex flex-wrap items-center gap-3 relative pb-5">
      <div class="flex flex-col gap-0">
        <span class="text-sm font-light">Username *</span>
        <input type="text" v-model="username" class="w-64 py-2 bg-zinc-900 rounded-md outline-none px-2">
      </div>
      <div class="flex flex-col gap-0">
        <span class="text-sm font-light">Email *</span>
        <input type="text" v-model="email" class="w-96 py-2 bg-zinc-900 rounded-md outline-none px-2">
      </div>
      <div class="flex flex-col gap-0">
        <span class="text-sm font-light">Temporary password</span>
        <input type="password" v-model="tempPass" class="w-52 py-2 bg-zinc-900 rounded-md outline-none px-2">
      </div>
      <div class="flex flex-col gap-0 capitalize">
        <span class="text-sm font-light">Role *</span>
        <div class="w-full md:w-max" :class="appLanguage.long == 'en-GB' ? 'min-w-[220px]' : 'min-w-[240px]'">
          <Multiselect
            mode="single"
            v-model="newRole"
            :options="getPossibleUserRoles(loggedUser)"
            label="name"
            value-prop="id"
            :placeholder="'Select role'"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
          />
        </div>
      </div>
      <div v-if="userIntegration === null" class="flex flex-col gap-0">
        <span class="text-sm font-light">Integration</span>
        <div class="w-full md:w-max" :class="appLanguage.long == 'en-GB' ? 'min-w-[235px]' : 'min-w-[260px]'">
          <Multiselect
            mode="single"
            :disabled="newRole == 'ludus_admin' || newRole == 'ludus_manager'"
            v-model="selectedIntegration"
            :options="allIntegrations"
            label="name"
            value-prop="name"
            :placeholder="'Select integration'"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
          />
        </div>
      </div>
      <div class="absolute bottom-0 left-0 font-light text-xs text-zinc-400">
        * Mandatory field
      </div>
    </div>
    <div class="flex w-full justify-center">
      <button @click="createUser" class="mt-5 fugaz uppercase pb-1 pt-1.5 px-4 rounded-md bg-zinc-700 w-max hover:text-orange-600">
        Create user
      </button>
    </div>
  </div>
</template>
<script>
import request from '@/services/axios'
import Multiselect from '@vueform/multiselect'

  export default {
    data () {
      return { 
        username: '',
        email: '',
        tempPass: '',
        newRole: null,
        newPlatform: null,
        allIntegrations: [],
        selectedIntegration: null,
      }
    },
    components: {
      Multiselect,
    },
    props: {
      checkValidEmail: Function,
      allUsers: Array,
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      loggedUser() {
        return this.$store.getters['user/user']
      },
      userIntegration() {
        if (this.loggedUser.attributes?.integration) {
          this.selectedIntegration = this.loggedUser.attributes.integration[0]
          return this.loggedUser.attributes.integration[0]
        } else return null
      },
    },
    watch: {
      newRole(val) {
        if (val === 'ludus_admin' || val === 'ludus_manager') {
          this.selectedIntegration = null
        }
      },
    },
    mounted() {
      this.getAllIntegrations()
    },
    methods: {
      getPossibleUserRoles() {
        if(this.loggedUser.attributes === undefined || Object.keys(this.loggedUser.attributes).length === 0) {
          return [
            'ludus_admin',
            'ludus_manager',
            'integration_admin',
            'integration_manager',
            'integration_guest',
          ]
        } else {
          return [
            'integration_admin',
            'integration_manager',
            'integration_guest',
          ]
        }
      },
      hasSpacesOrSpecialChars(str) {
        // Regular expression to match any character that is not a letter, number, hyphen, or underscore
        const regex = /[^a-zA-Z0-9-_]/;
        return regex.test(str);
      },
      async getAllIntegrations() {
        try {
          if (Object.keys(this.loggedUser.attributes).length > 0) {
            const integration = (await request.get(`/integrations/byName/${this.loggedUser.attributes.integration[0]}`)).data
            this.userIntegration = integration
          } else {
            const integrations = (await request.get('/integrations')).data.rows
            this.allIntegrations = integrations
          }
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
      generateErrorMessage(message) {
        this.$toast.open(
          {
            message: message,
            type: 'error',
            duration: 3000,
          }
        )
      },
      checkErrors() {
        if (!this.username) {
          this.generateErrorMessage('Username is required')
          return true
        }
        if (this.hasSpacesOrSpecialChars(this.username)) {
          this.generateErrorMessage('Username cannot contain spaces or special characters')
          return true
        }
        if (!this.email) {
          this.generateErrorMessage('Email is required')
          return true
        }
        if (this.allUsers.map(user => user.email).includes(this.email)) {
          this.generateErrorMessage('Email already exists')
          return true
        }
        if (this.allUsers.map(user => user.username).includes(this.username)) {
          this.generateErrorMessage('Username already exists')
          return true
        }
        if (!this.newRole) {
          this.generateErrorMessage('Role is required')
          return true
        }
      },
      async createUser() {
        try {
          if (this.checkErrors()) {
            return
          }
          if (!this.checkValidEmail(this.email)) {
            this.$toast.open(
              {
                message: 'Invalid email',
                type: 'error',
                duration: 3000,
              }
            )
            return
          }
          await request.post('/user/kc/new', {
            username: this.username,
            email: this.email,
            role: this.newRole,
            tempPass: this.tempPass,
            integration: this.selectedIntegration,
          })
          this.$toast.open(
            {
              message: 'User created successfully',
              type:'success',
              duration: 3000,
            }
          )
          this.username = ''
          this.email = ''
          this.tempPass = ''
          this.newRole = null
          this.newPlatform = null
          this.selectedIntegration = null
        } catch (e) {
          console.log(e)
          this.$toast.open(
            {
              message: 'Something went wrong',
              type: 'error',
              duration: 3000,
            }
          )
        }
      },
    },
  }

</script>
<style scoped>

</style>